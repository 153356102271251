import React, {useEffect} from "react";
import {StaticImage} from "gatsby-plugin-image";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import Accordion from '../../../../components/ui/legacy/Accordion/Accordion';
import {graphql} from 'gatsby';
import UpgraderModal from "../../../../components/modals/upgrader-modal";


//DOCUMENTS
import surfaceMountMetalPull from '../../../../documents/3rdGenSupport/StandardSurface-M.pdf'
import surfaceMount from '../../../../documents/3rdGenSupport/StandardSurface.pdf'
import surfaceMountWithPull from '../../../../documents/3rdGenSupport/StandardSurface-pull.pdf'
import recessMount from '../../../../documents/3rdGenSupport/Standard_Recess.pdf'
import recessMountWithPull from '../../../../documents/3rdGenSupport/Standard_Recess-pull.pdf'
import surfaceMountForMetal from '../../../../documents/3rdGenSupport/StandardSurface-M-pull.pdf'
import VerticalSurfaceM from '../../../../documents/3rdGenSupport/VerticalSurface-M.pdf'
import VerticalSurface from '../../../../documents/3rdGenSupport/VerticalSurface.pdf'
import VerticalSurfacePull from '../../../../documents/3rdGenSupport/VerticalSurface-pull.pdf'
import VerticalRecessMount from '../../../../documents/3rdGenSupport/VerticalRecess.pdf'
import VerticalRecessWithPull from '../../../../documents/3rdGenSupport/VerticalRecess-pull.pdf'
import VerticalSurfaceMountMetal from '../../../../documents/3rdGenSupport/VerticalSurface-pull-M.pdf'
import operatingProgramming from '../../../../documents/3rdGenSupport/Shared-Use-Inst.pdf'
import operatingProgrammingAssigned from '../../../../documents/3rdGenSupport/Assigned-Use-Inst.pdf'
import turnLed from '../../../../documents/legacy/support-pdf/OperatingProgramming/Flashing-LED.pdf'
import enableAutoUnlock from '../../../../documents/legacy/support-pdf/OperatingProgramming/Auto-unlock.pdf'
import Troubleshooting from '../../../../documents/3rdGenSupport/Troubleshooting.pdf'
import installEnglish from '../../../../documents/3rdGenSupport/installenglish.pdf'
import userEnglish from '../../../../documents/3rdGenSupport/userenglish.pdf'


const IndexPage = () => {
  const {t} = useTranslation();
  useEffect(() => {
    if (document.location.hash === '#sts') {
      document.getElementById('STS/STV/STH/ATS/ATV/ATH').click();
    }
    if (document.location.hash === '#sps') {
      document.getElementById('SPS/SPV/SPH/APS/APV/APH').click();
    }
    if (document.location.hash === '#hts') {
      document.getElementById('HTS/HTV/HTH/CTS/CTV/CTH').click();
    }
    if (document.location.hash === '#hps') {
      document.getElementById('HPS/HPV/HPH').click();
    }
  }, [])
  return (
      <Layout>
        <Seo title={t('support_electronic_locks_3rd_generation')}
             description={t('des_support_electronic_locks_3rd_generation')}/>
        <div className="support">
          <section className="hero">
            <div className="container">
              <div className={'tab-container'}>
                <ul className="tab-list unstyled-list">
                  <li className={'active'}>
                    3rd Generation
                  </li>
                </ul>
                <div className="tab-content-container t-series">
                  <div className={'tab-content active'}>

                    <div className="front-rear">
                        <StaticImage
                            src={'./images/third-generation.jpg'}
                            loading={'lazy'}
                            width={173}
                            height={217}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            alt="3rd Generation"
                        />
                    </div>
                    <div className="lists">
                      <Accordion clsName={'legacy-support'}>
                        <div label="STS/STV/STH/ATS/ATV/ATH">
                          <Accordion clsName={'legacy-support'}>
                            <div label="STS/ATS Installation Instructions">
                              <a href={surfaceMountMetalPull}
                                 target={'_blank'} rel={'noreferrer'}>
                                Surface Mount for Metal with Pull
                              </a>
                              <a href={surfaceMount}
                                 target={'_blank'} rel={'noreferrer'}>
                                Surface Mount
                              </a>
                              <a href={surfaceMountWithPull}
                                 target={'_blank'} rel={'noreferrer'}>
                                Surface Mount w/Pull
                              </a>
                              <a href={recessMount}
                                 target={'_blank'} rel={'noreferrer'}>
                                Recess Mount
                              </a>
                              <a href={recessMountWithPull}
                                 target={'_blank'} rel={'noreferrer'}>
                                Recess Mount w/Pull
                              </a>
                              <a href={surfaceMountForMetal}
                                 target={'_blank'} rel={'noreferrer'}>
                                Surface Mount for Metal
                              </a>
                            </div>
                            <div label="STV/ATV Installation Instructions">
                              <a href={VerticalSurfaceM} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={VerticalSurface} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={VerticalSurfacePull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={VerticalRecessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={VerticalRecessWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={VerticalSurfaceMountMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="Operating/Programming Instructions">
                              <a href={operatingProgramming} target={'_blank'} rel={'noreferrer'}>Operating/Programming</a>
                              <a href={turnLed} target={'_blank'} rel={'noreferrer'}>Turn On/Off the LED</a>
                              <a href={enableAutoUnlock} target={'_blank'} rel={'noreferrer'}>Enable/Disable&nbsp;Automatic Unlock</a>
                            </div>
                            <div label="Troubleshooting">
                              <a href={Troubleshooting} target={'_blank'} rel={'noreferrer'}>Troubleshooting</a></div>
                          </Accordion>
                        </div>
                        <div label="SPS/SPV/SPH/APS/APV/APH">
                          <Accordion clsName={'legacy-support'}>
                            <div label="SPS/APS Installation Instructions">
                              <a href={surfaceMountMetalPull} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={surfaceMount} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={surfaceMountWithPull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={recessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={recessMountWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={surfaceMountForMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="SPV/APV Installation Instructions">
                              <a href={VerticalSurfaceM} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={VerticalSurface} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={VerticalSurfacePull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={VerticalRecessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={recessMountWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={surfaceMountForMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="Operating/Programming Instructions">
                              <a href={operatingProgrammingAssigned} target={'_blank'} rel={'noreferrer'}>Operating/Programming</a>
                            </div>
                            <div label="Troubleshooting">
                              <a href={Troubleshooting} target={'_blank'} rel={'noreferrer'}>Troubleshooting</a>
                            </div>
                          </Accordion>
                        </div>
                        <div label="HTS/HTV/HTH/CTS/CTV/CTH">
                          <Accordion clsName={'legacy-support'}>
                            <div label="HTS Installation Instructions">
                              <a href={surfaceMountMetalPull} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={surfaceMount} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={surfaceMountWithPull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={recessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={recessMountWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={surfaceMountForMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="HTV Installation Instructions">
                              <a href={VerticalSurfaceM} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={VerticalSurface} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={VerticalSurfacePull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={VerticalRecessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={VerticalRecessWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={VerticalSurfaceMountMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="Operating/Programming Instructions">
                              <a href={operatingProgramming} target={'_blank'} rel={'noreferrer'}>Operating/Programming</a>
                              <a href={turnLed} target={'_blank'} rel={'noreferrer'}>Turn On/Off LED</a>
                              <a href={enableAutoUnlock} target={'_blank'} rel={'noreferrer'}>Enable/Disable Automatic Unlock</a>
                              <a href={installEnglish} target={'_blank'} rel={'noreferrer'}>Software Installation Guide</a>
                              <a href={userEnglish} target={'_blank'} rel={'noreferrer'}>Software User Guide</a>
                            </div>
                            <div label="Troubleshooting">
                              <a href={Troubleshooting} target={'_blank'} rel={'noreferrer'}>Troubleshooting</a>
                            </div>
                          </Accordion>
                        </div>
                        <div label="HPS/HPV/HPH">
                          <Accordion clsName={'legacy-support'}>
                            <div label="HPS Installation Instructions">
                              <a href={surfaceMountMetalPull} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={surfaceMount} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={surfaceMountWithPull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={recessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={recessMountWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={surfaceMountForMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="HPV Installation Instructions">
                              <a href={VerticalSurfaceM} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal with Pull</a>
                              <a href={VerticalSurface} target={'_blank'} rel={'noreferrer'}>Surface Mount</a>
                              <a href={VerticalSurfacePull} target={'_blank'} rel={'noreferrer'}>Surface Mount w/Pull</a>
                              <a href={VerticalRecessMount} target={'_blank'} rel={'noreferrer'}>Recess Mount</a>
                              <a href={VerticalRecessWithPull} target={'_blank'} rel={'noreferrer'}>Recess Mount w/Pull</a>
                              <a href={VerticalSurfaceMountMetal} target={'_blank'} rel={'noreferrer'}>Surface Mount for Metal</a>
                            </div>
                            <div label="Operating/Programming Instructions">
                              <a href={operatingProgrammingAssigned} target={'_blank'} rel={'noreferrer'}>Operating/Programming</a>
                              <a href={installEnglish} target={'_blank'} rel={'noreferrer'}>Software Installation Guide</a>
                              <a href={userEnglish} target={'_blank'} rel={'noreferrer'}>Software User Guide</a>
                            </div>
                            <div label="Troubleshooting">
                              <a href={Troubleshooting} target={'_blank'} rel={'noreferrer'}>Troubleshooting</a>
                            </div>
                          </Accordion>
                        </div>
                      </Accordion>
                    </div>
                    <UpgraderModal showLink={true}/>
                  </div>
                </div>

              </div>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
